.regular{
    width: 100%;
    border-radius: 30px;
    padding: 0.5rem;
    padding-bottom: 1.5rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    background: linear-gradient(180deg, rgba(88, 202, 227, 0.8) 0%, rgba(88, 202, 227, 0.302425) 62.2%, rgba(88, 202, 227, 0) 100%); 
   }
  @media (min-width: 768px) {
    .regular{
        padding: 1.5rem;
        width: 350px;
    }
  }
  
  .regular .image{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    padding-top: 1rem;
  }
  
  .regular .image img{
    width: 65px;
    height: 75px;
  }
  
  .regular .image .text{
    background-color: #D96750;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
  }
  
  .regular .box{
    margin-bottom: 3rem;
  }
  
  .regular .box .part{
    margin-bottom: 0.75rem;
    font-size: 18px;
    font-weight: 400;
  }
  .regular .join{
    display: flex;
    justify-content: center;
  }
  
  .regular .join button{
    background-color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    width: 248px;
    height: 50px;
    font-weight: 700;
    font-size: 20px;
  }
  
  .regular .memebr-name{
    position: absolute;
    font-size: 25px;
    font-weight: 700;
    height: 55px;
    width: 207px;
    top: -40px;
    background-color: #FFF2F2;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  