.request-session{
    margin-top: 130px;
    padding-bottom: 80px;
  }
  .request-session .request-wrapper{
    display: flex;
    flex-direction: column;
    column-gap: 7rem;
    row-gap: 1.75rem;
  }
  @media (min-width: 768px) {
    .request-session .request-wrapper{
        flex-direction: row;
    }
  }
  
  @media (min-width: 768px) {
    .request-session .menu-wrapper{
        width: 35%;
    }
  }
  @media (min-width: 1024px) {
    .request-session .menu-wrapper{
        width: 25%;
    }
  }
  @media (min-width: 1280px) {
    .request-session .menu-wrapper{
        width: 22%;
    }
  }
  
  .request-session .content{
    width: 100%;
  }
  @media (min-width: 768px) {
    .request-session .content{
        width: 60%;
    }
  }
  @media (min-width: 1024px) {
    .request-session .content{
        width: 70%;
    }
  }
  @media (min-width: 1280px) {
    .request-session .content{
        width: 78%;
    }
  }
  
  .request-session .content .content-wrapper{
    display: grid;
    gap: 30px;
    align-items: flex-start;
  }
  @media (min-width: 1280px) {
    .request-session .content .content-wrapper{
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  
  .request-session .content-form{
    order: 2;
  }
  @media (min-width: 1280px) {
    .request-session .content-form{
        order: 1;
    }
  }
  
  .request-session .content-title{
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }
  @media (min-width: 768px) {
    .request-session .content-title{
        font-size: 22px;
        margin-bottom: 3rem;
    }
  }
  @media (min-width: 1024px) {
    .request-session .content-title{
        font-size: 25px;
    }
  }
  
  .request-session .form{
    max-width: 100%;
  }
  
  .request-session .form-box{
    margin-bottom: 1.25rem
  }
  
  .request-session .form-box .form-label{
    font-size: 22px;
    font-weight: 700;
    color: #000000;
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .request-session .form-box .form-input,
  .request-session .form-box  .form-area{
    display: block;
    border: 1px solid #18A0FB;
    width: 100%;
    outline: none;
    border-radius: 0.375rem;
    padding: 0.5rem;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  }
  @media (min-width: 768px) {
    .request-session .form-box .form-input,
    .request-session .form-box  .form-area{
        width: 407px;
    }
  }
  .request-session .form-box .form-input{
    height: 45px;
  }
  .request-session .form-box  .form-area{
    height: 253px;
  }
  
  .request-session .box-radio{
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
  }
  
  .request-session .box-file{
    display: flex;
    width: 250px;
    max-width: 100%;
    height: 35px;
    background-color: #18a0fb66;
    border-radius: 0.5rem;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.25rem;
  }
  
  .request-session .box-file input{
    display: none;
  }
  
  .request-session .box-file .icon{
    color: #6F6060;
  }
  
  .request-session .box-file label{
    color: #6F6060;
    font-size: 14px;
  }
  
.request-session .request-btn-wrapper{
    width: 100%;
}

  .request-session .requset-btn{
    width: 100%;
    height: 45px;
    border-radius: 0.5rem;
    color: #FFFFFF;
    background: #18A0FB;
  }
  @media (min-width:640px)
{
  .request-session .requset-btn{
    width: 350px;
  }
}
  
  .request-session .session{
    order: 1;
  }
  @media (min-width: 1280px) {
    .request-session .session{
        order: 2;
    }
  }
  
  .request-session .session img{
    max-width: 100%;
  }
  
  .request-session .session .box{
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .request-session .session .box-title{
    font-size: 25px;
    font-weight: 700;
    color: #000000;
  }
  
  .request-session .session .counter{
    column-gap: 0.75rem;
    display: flex;
  }
  
  .request-session .session .box-counter{
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
  }
  
  .request-session .session .counter-text{
    font-size: 18px;
    font-weight: 700;
    color: #000000;
  }
  