.url-lesson{
    margin-top: 130px;
  }
  
  .url-lesson .groups-wrapper{
    display: flex;
    column-gap: 7rem;
    row-gap: 1.75rem;
    flex-direction: column;
  }
  
  @media (min-width: 768px) {
    .url-lesson .groups-wrapper{
        flex-direction: row;
    }
  }
  
  @media (min-width: 768px) {
    .url-lesson .category-wrap{
        width: 35%;
    }
  }
  
  @media (min-width: 1024px) {
    .url-lesson .category-wrap{
        width: 25%;
    }
  }
  
  .url-lesson .groups-content{
    width: 100%;
  }
  
  @media (min-width: 768px) {
    .url-lesson .groups-content{
        width: 60%;
    }
  }
  
  @media (min-width: 1024px) {
    .url-lesson .groups-content{
        width: 70%;
    }
  }
  
  @media (min-width: 1280px) {
    .url-lesson .groups-content{
        width: 78%;
    }
  }
  
  .url-lesson .title{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }
  
  @media (min-width: 768px) {
    .url-lesson .title{
        margin-bottom: 3rem;
    }
  }
  
  @media (min-width: 1024px) {
    .url-lesson .title{
        font-size: 32px;
    }
  }
  
  @media (min-width: 768px) {
    .url-lesson .title{
        font-size: 28px;
    }
  }
  .url-lesson .lesson-name{
    font-size: 20px;
    font-weight: 500;
    color: #000000;
  }
  @media (min-width: 768px) {
    .url-lesson .lesson-name{
        font-size: 28px;
        padding-right: 48px;
    }
  }
  .url-lesson .url-component-wrapper{
    margin-top: 120px;
  }