.forum-wrapper{
    border-radius: 6px;
    background-color: white;
    padding: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 340px;
    max-width: 100%;
    margin: 0 auto;
}
.forum-wrapper .image{
    width: 100%;
    height: 194px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.forum-wrapper .wrapper-content{
    padding:0 12px 12px;
}
.forum-wrapper .wrapper-content .heading-2 {
    font-size: 14px;
    font-weight: bold;
    color: #000000;
}
.forum-wrapper .wrapper-content .name{
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}
.forum-wrapper .wrapper-content .controls{
    display: flex;
    justify-content: space-around;
    margin-top: 12px;
}
.forum-wrapper .wrapper-content .controls .follow-us{
    width: 137px;
    height: 35px;
    background-color: #18A0FB;
    font-size: 18px;
    color: white;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}