.course{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 10px;
    width:100%
}
.course .image{
    width: 267px;
    height: 194px;
    display: flex;
    width:100%
}

.course .course-content{
    padding: 8px 10px;
}

.course .course-header{
    display: flex;
    justify-content: space-between;
}

.course .course-title{
    font-size: 14px;
    font-weight: 700;
    color: #000000;
}

.course .course-price{
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    display: flex;
    column-gap: 4px;
    align-items: flex-end;
    padding: 4px 0;
}

.course .course-icon{
    color: #242424;
}

.course .course-teacher{
    font-size: 16px;
    font-weight: 400;
    margin-top: 8px;
    color: #000000;
}

.course .course-footer{
    padding: 10px 6px 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.course .link{
    width: 137px;
    height: 35px;
    display: block;
    background-color: #18A0FB;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.course .icons{
    display: flex;
    column-gap: 6px;
    align-items: center;
}

.course .icons .heart{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #F40A0A;
    display: flex;
    justify-content: center;
    align-items: center;
}

.course .icons .icon-share{
    color: black;
    font-size: 26px;
}