.about-wrapper{
    padding: 40px 0;
}
.about-wrapper .heading-3{
    font-size: 23px;
    font-weight: bold;
    margin-bottom: 16px;
}
@media (min-width: 1024px) {
    .about-wrapper .heading-3 {
        font-size: 25px;
    }
}
.about-wrapper .description{
    font-size: 18px;
    font-weight: 400;
    padding: 0 8px;
}
@media (min-width: 768px) {
    .about-wrapper .description{
        font-size: 20px;
    }
}