.works{
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #DCF4FA;
  }
  
  .works-wrapper{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 1rem
  }
  .works .image{
    width: 100%;
  }
  
  @media (min-width: 768px) {
    .works-wrapper{
      width: 80% !important;
    }
    .works .image{
        width: 360px;
    }
  }
  
  .works .img{
    height: 220px;
    width: 330px;
    border-radius: 18px;
    margin-left: auto;
    margin-right: auto;
  }
  
  @media (min-width:768px)
  {
    .works .img{
        margin-left: 0px;
        margin-right: 0px;
      }
  }
  
  @media (min-width: 768px) {
    .works .text{
        flex: 1 1 0%;
    }
  }
  
  .works .title{
    font-size: 22px;
    font-weight: 700;
    color:#000000;
    letter-spacing: -1.5%;
  }
  
  .works .description{
    margin-top: 0.75rem;
    color: #000000cc;
    font-size: 18px;
    line-height: 30px;
  }
  @media (min-width: 768px) {
    .works .description{
      padding-right: 20px;
    }
  }
  @media (min-width: 1024px) {
    .works .description{
      padding-right: 80px;
    }
  }

  