
.land-parent .parent-land-content .content-bar{
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    column-gap: 30px;
    row-gap: 20px;
    margin-top: 25px;
}

@media (min-width:768px)
{
    .land-parent .parent-land-content .content-bar{
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media (min-width:1280px)
{
    .land-parent .parent-land-content .content-bar{
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

.land-parent .parent-land-content .content-bar .box{
    text-align: center;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 30px 10px;
}

.land-parent .parent-land-content .content-bar .box-icon{
    font-size: 46px;
    margin-bottom: 14px;
}

.land-parent .parent-land-content .content-bar .box-num,
.land-parent .parent-land-content .content-bar .box-title{
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 6px;
}