.land-parent{
    background-color: #F8F9FD ;
    padding-bottom: 40px;
}

.chart-single .chart-single-header{
    display: flex;
    width: 100%;
    column-gap: 20px;
    padding-top: 40px;
    flex-direction: column;
}
@media (min-width:768px)
{
    .chart-single .chart-single-header{
        flex-direction: row;
        align-items: center;
    }
}

.chart-single .chart-single-print{
    margin-top: 30px;
    width: 230px;
    height: 74px;
}

.chart-single .chart-single-mainchart{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@media (min-width:768px)
{
    .chart-single .chart-single-mainchart{
        flex-direction: column;
    }
}

.chart-single .chart-single-header .chart-single-average-degree,
.chart-single .chart-single-header .chart-single-rate{
    font-weight: 500;
    font-size: 20px;
}

.chart-single .chart-single-header .chart-single-average-degree span,
.chart-single .chart-single-header .chart-single-rate{
    color: #64B161;
}

.chart-single .chart-single-header .chart-single-rate{
    margin-bottom: 16px;
}

.chart-single .chart-single-images{
    display: flex;
    column-gap: 12px;
}
.chart-single .chart-single-images img{
    width: 92px;
    height: 92px;
}

.chart-single .chart-single-text-wrapper{
    display: flex;
    column-gap: 30px;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 20px;
}

.chart-single .chart-single-text-wrapper .chart-single-text-title{
    font-size: 16px;
}
@media (min-width:768px)
{
    .chart-single .chart-single-text-wrapper .chart-single-text-title{
        font-size: 18px
    }
}

.chart-single .chart-single-text-summary{
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 50px;
}
@media (min-width:768px)
{
    .chart-single .chart-single-text-summary{
        font-size: 18px
    }
}

.chart-single .chart-single-text-wrapper .chart-single-title-image{
    width: 64px;
    height: 60px;
}

.chart-single .chart-single-mainchart{
    width: 100%;
    margin-bottom: 50px;
    background-color: white;
    padding: 30px;
    margin: auto;
}

@media (min-width:1024px)
{
    .chart-single .chart-single-mainchart{
        width: 70%;
    }
}

.chart-single .chart-single-charts{
    display: grid;
    grid-template-columns: repeat(1,minmax(0,1fr));
    column-gap: 22px;
    row-gap: 22px;
}
@media (min-width:640px)
{
    .chart-single .chart-single-charts{
        grid-template-columns: repeat(2,minmax(0,1fr));
    }
}
@media (min-width:1024px)
{
    .chart-single .chart-single-charts{
        grid-template-columns: repeat(3,minmax(0,1fr));
    }
}
.chart-single .gold-achievements-chart{
    width: 100%;
}

.chart-single .gold-achievements-chart-wrapper{
    background-color: #FFFFFF;
    padding: 10px;
}

.chart-single .gold-achievements-chart .gold-achievements-chart-title{
    font-size: 18px;
    margin-top: 8px;
    font-weight: 600;
    text-align: center;
}

.chart-single .chart-single-title{
    font-size: 20px;
    font-weight: 600;
    margin-top: 20px;
    text-align: center;
}