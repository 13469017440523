.psychologist-bar .lessons-title{
    font-size: 22px;
    font-weight: 700;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #C4C4C4;
  }
  
  @media (min-width: 640px) {
    .psychologist-bar .lessons-title{
        font-size: 24px;
    }
  }
  
  @media (min-width: 768px) {
    .psychologist-bar .lessons-title{
        font-size: 26px;
    }
  }
  
  .psychologist-bar .bar-wrapper{
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
  }
  
  @media (min-width: 768px) {
    .psychologist-bar .bar-wrapper{
        flex-direction: column;
    }
  }
  
  .psychologist-bar .link{
    transition-duration: 300ms;
    border-radius: 6px;
    font-size: 20px;
    font-weight: 500;
    display: block;
    padding: .25rem .5rem;
    color: #000000;
  }
  .psychologist-bar .link:hover{
    background-color: #99DAE9;
  }
  
  @media (min-width: 768px) {
    .psychologist-bar .link {
        font-size: 24px;
    }
  }
  
  .psychologist-bar .bar-wrapper .active{
    background-color: #99DAE9;
  }
  