.boxlink .boxlink-title{
    font-size: 20px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 8px;
}
@media (min-width:768px)
{
    .boxlink .boxlink-title{
        font-size: 28px;
        padding-right: 48px;
    }
}

.boxlink .boxlink-link{
    font-weight: 500;
    font-size: 20px;
    color: #3C58A0;
    word-break: break-all;
}
@media (min-width:768px)
{
    .boxlink .boxlink-link{
        font-size: 28px;
    }
}