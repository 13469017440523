*{
  margin: 0;
  padding: 0;  
  box-sizing: border-box; 
  font-family: 'Tajawal', sans-serif !important;
  user-select: none;
}
body{
  direction: rtl;

}
html{
  scroll-behavior: smooth;
}
ul{
  list-style:none;
  padding:0
}
a{
  text-decoration: none;
  color:black
}
button{
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.container{
  padding-left:8px;
  padding-right:8px;
  margin-left:auto;
  margin-right:auto;
  width: 100%;
}
/* small */ 
@media (min-width:640px)
{
  .container{
      max-width: 100%;
  }
}
@media (min-width:768px)
{
  .container{
      max-width: 100%;
  }
}
/* meduim */ 
@media (min-width:1024px)
{
  .container{
      max-width: 100%;
  }
}
/* large */
@media (min-width:1280px)
{
  .container{
      max-width: 100%;
      padding-left: 24px;
      padding-right: 24px;
  }
}
@media (min-width:1536px)
{
  .container{
      max-width: 100%;
  }
}
label:has(.favorite){
  display: grid;
  place-items: center;
}
.favorite{
  align-items: center;
  color: gray;
  display: flex;
  height: 29px;
  justify-content: center;
  width: 29px;
  margin-left: 10px;
  cursor: pointer;
}
input[name="favorite"]:checked + .favorite{
  color: #e2264d;
}

.overlay::before{
    content:"";
    width:100%;
    height: 100%;
    top:0;
    left:0;
    position: absolute;
    background-color: rgba(0, 0, 0, 50%);
    z-index: 5;
}