.session-box{
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}
.session-box .image{
    border-radius: 8px 8px 0 0;
}
.session-box .box-wrapper{
    padding:0 12px;
    margin: 12px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.session-box .session-name{
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
    letter-spacing: -0.015em;
    color: #000000;
}
.session-box .moreInfo-wrapper{
    display: flex;
    gap: 0 12px;
}
.session-box .info-item{
    display: flex;
    align-items: center;
    gap: 0 4px;
}
.session-box .info-item .detail{
    color: #000000;
    font-size: 18px;
    font-weight: bold;
}
.session-box .controls-session{
    display: flex;
    justify-content: flex-end;
    gap: 0 48px;
    margin-bottom: 16px;
    padding: 0 12px;
}
.session-box .controls-session .btn-request-session{
    width: 129.69px;
    height: 32.22px;
    background-color: #18A0FB;
    border-radius: 8px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}
.session-box .controls-session .love-session{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #F40A0A;
    display: flex;
    align-items: center;
    justify-content: center;
}