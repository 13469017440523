.services{
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .services .title{
    font-weight: 700;
    font-size: 30px; 
  }
  
  .services .content{
    display: grid;
    margin-top: 40px;
    row-gap: 40px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  
  @media (min-width:768px)
  {
    .services .content{
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
  }
  .services .box{
    margin-top: 32px;
    margin-bottom: 32px;
  }
  
  .services .lessons-link{
    display: block;
    text-align: center;
    margin: 0;
    padding: 5px 10px;
    margin-right: auto;
    margin-left: 10%;
    background-color: #18A0FB;
    color: #fff;
    border-radius: 5px;
  }
  
  .services .text{
    display: flex;
    align-items: center;
    column-gap: 0.75rem
  }
  
  .services .image{
    width: 54px;
    height: 54px;
    border-radius: 9999px;
  }
  
  .services .image img{
    width: 48px;
    height: 46px;
    border-radius: 9999px;
  }
  
  .services .box-title{
    font-size: 22px;
    font-weight: 700;
    color: #000000;
  }
  
  .services .box-descirption{
    font-size: 18px;
    color: #000000cc;
    line-height: 30px;
  } 