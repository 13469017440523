.footer{
    background-color: #387482;
  }
  .footer .footer-container{
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .footer .footer-wraper{
    display: flex;
    gap: 1rem;
    flex-direction: column;
    row-gap: 2rem;
  }
  
  @media (min-width: 640px) {
    .footer .footer-wraper{
        flex-direction: row;
    }
  }
  
  .footer .content{
    width: 100%;
  }
  @media (min-width: 640px) {
    .footer .content{
        width: 60%;
    }
  }
  @media (min-width: 1280px) {
    .footer .content{
        width: 65%;
    }
  }
  
  .footer .content .title{
    color: white;
    font-size: 23px;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }

  @media (min-width: 1280px) {
    .footer .content .title{
        font-size: 25px;
    }
  }
  
  .footer .box-wrapper{
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
  }
  
  @media (min-width: 640px) {
    .footer .box-wrapper{
        gap: 0px;
        justify-content: space-between;
    }
  }
  
  @media (min-width: 1024px) {
    .footer .box-wrapper{
        width: 80%;
    }
  }
  
  .footer .box-links{
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    font-size: 16px;
    font-weight: 400;
    color:#FFFFFF;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .footer .data{
    display: flex;
  }
  
  .footer .data .line{
    width: 1px;
    height: 248px;
    margin-left: auto;
    margin-right: auto;
    background-color: #FFFFFF;
    opacity: 0.4;
    margin: auto;
    display: none;
  }
  @media (min-width: 640px) {
    .footer .data .line{
        display: block;
    }
  }
  
  .footer .data-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  @media (min-width: 640px) {
    .footer .data-wrapper{
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
  
  @media (min-width: 1280px) {
    .footer .data-wrapper{
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }
  }
  
  .footer .data-title{
    color: white;
    font-size: 23px;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }
  
  @media (min-width: 1280px) {
    .footer .data-title{
        font-size: 25px;
    }
  }
  
  .footer .data-socials{
    font-size: 38px;
    display: flex;
    gap: 1.5rem;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  @media (min-width: 640px) {
    .footer .data-socials{
        font-size: 42px;
    }
  }
  
  @media (min-width: 1280px) {
    .footer .data-socials{
        font-size: 48px;
    }
  }

  .footer .data-socials li{
    display: grid;
    place-items: center;
  }
  .footer .data-socials li img{
    width: 35px;
  }
  
  .footer .data-socials img.youtube{
    transform: translateY(6px);
    width: 40px;
  }
  .footer .note-text{
    font-size: 16px;
    font-weight: 700;
    color: white;
  }