.singleGroup{
    padding-top: 130px;
    padding-bottom: 100px;
  }
  
  .singleGroup .group-title{
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 3rem;
  }
  
  .singleGroup .group-wrapper{
    display: flex;
    column-gap: 8rem;
    row-gap: 3.5rem;
    flex-direction: column;
  }
  
  @media (min-width: 1024px) {
    .singleGroup .group-wrapper{
        flex-direction: row;
    }
  }
  
  .singleGroup .group-content{
    width: 100%;
  }
  
  @media (min-width: 1024px) {
    .singleGroup .group-content{
        width: 55%;
    }
  }
  
  .singleGroup .group-image{
    width: 557.5px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 6px;
    margin-bottom: 1rem;
  }
  
  .singleGroup .calender{
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .singleGroup .calender .icon{
    color: #E80054;
    font-size: 45px;
  }
  .singleGroup .time{
    font-size: 18px;
    color: #868383;
    font-weight: 400;
    max-width: 300px;
  }
  
  .singleGroup .content{
    margin-top: 2rem
  }
  
  .singleGroup .content-title{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  
  @media (min-width: 640px) {
    .singleGroup .content-title{
        font-size: 22px;
    }
  }
  
  .singleGroup .content-description{
    font-size: 16px;
    font-weight: 400;
  }
  
  @media (min-width: 640px) {
    .singleGroup .content-description{
        font-size: 18px;
    }
  }
  
  .singleGroup .group-event{
    margin-top: 3rem;
    display: flex;
    justify-content: flex-end;
  }
  
  .singleGroup .group-event .btn,
  .singleGroup .join-event .btn{
    width: 250px;
    height: 45px;
    color: white;
    background-color: #18A0FB;
    font-size: 18px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition-duration: .2s;
  }
  .singleGroup .group-event .btn:hover,
  .singleGroup .join-event .btn:hover{
    background-color: #FFFFFF;
    border: 1px solid #18A0FB;
    color: #18A0FB;
  }
  
  .singleGroup .data-wrapper{
    background-color: white;
    border-radius: 8px;
    padding: 24px 12px 12px;
    max-width: 373px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  @media (max-width: 640px) {
    .singleGroup .data-wrapper{
      margin: 0 auto;
    }
  }
  
  .singleGroup .data-wrapper .data{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  
  .singleGroup .data .group-title{
    font-size: 14px;
    font: 700;
  }
  
  .singleGroup .data .count{
    width: 115px;
    background-color: #4DCABD;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0.5rem;
    border-radius: 10px;
    padding-right:.5rem;
  }
  
  .singleGroup .data .number{
    font-size: 14px;
    font-weight: 400;
  }
  
  .singleGroup .data .number{
    font-size: 16px;
    font-weight: 700;
  }
  
  .singleGroup .teacher-wrapper{
    display: flex;
    align-items: flex-start;
    margin-top: 3rem;
    gap: 0.5rem;
  }
  
  .singleGroup .data-icon{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #FFAA20;
    z-index: 40;
    overflow: hidden;
  }
  
  .singleGroup .data-icon .icon{
    font-size: 24px;
    color: white;
  }
  
  .singleGroup .group-teacher{
    font-size: 16px;
    font-weight: 700;
  }
  
  .singleGroup .join-event{
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;
    margin-bottom: 0.75rem;
    padding:0 6px
  }
  
  .singleGroup .join-event .loveIcon{
    width: 35px;
    height: 35px;
    border-radius:50%;
    background-color: #F40A0A;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  