.navBar{
    position: fixed;
    top: 0;
    right: 0;
    height: 80px;
    width:100%;
    background-color: #FFFFFF;
    z-index: 100;
    box-shadow: 0px 3px 10px rgb(0 0 0 / 15%);
}
.nav-container{
    height: 100%;
    margin: 0 auto;
}
.nav-wrapper{
    display: flex;
    align-items: center;
    height: 100%;
    gap: 0px 22px;
}
.nav-logo{
    display: flex;
    align-items: center;
    gap: 0px 16px;
    flex-grow: 1;
}
@media (min-width:1290px)
{
    .nav-logo{
        flex-grow: 0;
    }
}
.nav-logo .heading-two , .nav-logo .heading-three{
    font-size: 28px;
    font-size: 400;
    letter-spacing: -0.015em;
}
.nav-logo .logo-nav{
    width: 60px;
    height: 45px;
}
.nav-logo .heading-three{
    color: #242424;
}
.search-form-first{
    border: 1px solid #24242480;
    width: 325px;
    height: 48px;
    border-radius: 28px;
    display: none;
    padding: 8px;
}
@media (min-width:640px)
{
    .search-form-first{
        display: flex;
        align-items: center;
        gap:0 50px;
    }
}
@media (min-width:1290px)
{
    .search-form-first{
        display: none;
    }
}
.search-icon{
    font-weight: 500;
    font-size: 20px;
    color: #24242480;
    background-color: transparent;
    border: none;
}
.search-input{
    outline: none;
    border: none;
    font-size: 20px;
    flex: 1;
}
.toggle-show{
    font-size: 30px;
    outline: none;
    border: none;
    display: grid;
    place-items: center;
}
@media (min-width:1290px)
{
    .toggle-show{
        display: none;
    }
}
.links-wrapper{
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    position: absolute;
    top: 80px;
    background-color: #FFFFFF;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    width: 300px;
    max-width: 100%;
    height: calc(100vh - 80px);
    transition: all 0.2s ease-out;
}
@media (min-width:1290px)
{
    .links-wrapper{
        transition: all 0s ease-out;
        gap:10px 20px;
        display: flex;
        justify-content: space-between;
        flex: 1;
        flex-direction: row;
        position: relative;
        top: 0;
        background-color: transparent;
        box-shadow: none;
        height: initial;
    }
}
.links-wrapper.show{
    display: flex;
    left: 0;
}
.navBar:has(.links-wrapper.show) + .page-overlay::before{
    content: '';
    width: 100vw;
    height: 100vh;
    background-color: rgb(0 0 0 / 0.6);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2;
}
.links-wrapper.notShow{
    left: -300px;
}
@media (min-width:1290px)
{
    .navBar:has(.links-wrapper.show) + .page-overlay::before{
        content: '';
        width: 0;
        height: 0;
        background-color: transparent;
        position: initial;
        left: 0;
        top: 0;
        z-index: 0;
    }
    .links-wrapper.notShow{
        display: flex;
        left: 0;
    }
}
.pages-links-wrapper{
    display: flex;
    gap:30px 12px;
    flex-direction: column;
    margin-bottom: 16px;
}
@media (min-width:1290px)
{
    .pages-links-wrapper{
        gap:12px 12px;
        flex-direction: row;
        margin-bottom: 0;
    }
}
.pages-links-wrapper .page-link{
    font-weight: 500;
    font-size: 17px;
    color: #242424;
    text-align: center;
}
.pages-links-wrapper .page-link:hover{
    color: #18A0FB;
}
.pages-links-wrapper .active{
    color: #18A0FB;
    position: relative;
}
.pages-links-wrapper .active::after{
    content: '';
    width: 100%;
    height: 1.5px;
    background-color: #18A0FB;
    position: absolute;
    left: 0;
    bottom: -3px;
}
.search-form-second{
    border: 1px solid #24242480;
    width: 230px;
    height: 42px;
    border-radius: 28px;
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 8px;
    order: -1;
}
@media (min-width: 1290px) {
    .search-form-second{
        order: 0;
    }
}
.search-form-second .search-icon{
    display: block;
    margin-top: 4px;
}
.search-form-second .search-input{
    flex: 1;
    height: 100%;
    width: 100%;
    background-color: transparent;
}
.register-links-wrapper{
    display: flex;
    gap: 12px 10px;
    flex-direction: column;
    margin-bottom: 16px;
}
@media (min-width:1290px)
{
    .register-links-wrapper{
        margin-bottom: 0px;
        flex-direction: row;
    }
}
.register-links-wrapper .register-link{
    width: 130px;
    height: 40px;
    border-radius: 6px;
    color: #FFFFFF;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.register-links-wrapper .register-link:first-child{
    background-color: #18A0FB;
}
.register-links-wrapper .register-link:not(:first-child){
    border: 1px solid #18A0FB;
    color: #18A0FB;
    transition-duration: 0.2s;
}
.register-links-wrapper .register-link:not(:first-child):hover ,.language-translate:hover{
    background-color: #18A0FB;
    color: #FFFFFF;
}
.language-translate{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #18A0FB;
    color: #18A0FB;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    margin: 0 auto;
    cursor: pointer;
    transition-duration: .2s;
}