.lesson{
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    width: 300px;
    max-width: 100%;
    margin: 0 auto;
}

.lesson .lesson-img{
    width: 100%;
    height: 150px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.lesson .lesson-box-content{
    padding: 12px
}

.lesson .lesson-box-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 8px;
}

.lesson .lesson-box-wrapper .lesson-title-heading{
    font-size: 14px;
    font-weight: 700;
    color: #000000;
}

.lesson .lesson-teacher{
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    margin-top: 10px;
    display: block;
}

.lesson .lesson-ecersice{
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px;
    padding-left: 4px;
    padding-right: 4px; 
    color: #000000;  
}

.lesson .isTrain{
    background-color: #D96750;
}

.lesson .isLesson{
    background-color: #99DAE9;
}

.lesson .lesson-view{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
}

.lesson .lesson-view-wrapper{
    display: flex;
    align-items: center;
    gap: 8px;
}

.lesson .lesson-link{
    width: 137px;
    height: 35px;
    background-color: #18A0FB;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
}

.lesson .image{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #F40A0A;
    display: flex;
    align-items: center;
    justify-content: center;
}